<template>
  <div class="indicator-detail">
    <van-nav-bar
      :title="pageTitle"
      left-arrow
      fixed
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <i class="history-list iconfont iconfont-lishijilu"></i>
      </template>
    </van-nav-bar>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="indicator-detail-subtitle">
        <span class="subtitle">实时参数</span>
        <span
          class="iconfont iconfont-zhexiantu"
          @click="showEcharts(true)"
        ></span>
      </div>
      <div class="indicator-detail-table">
        <table>
          <tr>
            <td>点位名称</td>
            <td>{{ model.name }}</td>
            <td>位号</td>
            <td>{{ model.num }}</td>
          </tr>
          <tr>
            <td>监测值</td>
            <td
              :class="[
                { blue: model.value !== defaultValue },
                { red: model.handleStatus === 0 }
              ]"
            >
              {{ model.value }} {{ model.indicatorUnitName }}
            </td>
            <td>采集时间</td>
            <td>{{ model.collectTime }}</td>
          </tr>
          <tr>
            <td>监测项</td>
            <td>{{ model.indicatorTypeName }}</td>
            <td>报警阈值</td>
            <td>{{ model.threshold }}</td>
          </tr>
          <tr>
            <td>报警级别</td>
            <td :class="{ red: model.alarmName !== defaultValue }">
              {{ model.alarmName }}
            </td>
            <td>报警时长</td>
            <td>{{ model.alarmTime }}</td>
          </tr>
        </table>
      </div>
      <div class="indicator-detail-list">
        <van-cell title="所属部门" :value="model.departmentName" />
        <van-cell title="所属设施" :value="model.poiName" />
        <van-cell title="所属危险源" :value="model.dangerName" />
        <van-cell title="值班电话">
          <template #default>
            <a
              v-if="model.dutyMobile !== defaultValue"
              class="blue"
              :href="'tel:' + model.dutyMobile"
              >{{ model.dutyMobile }}</a
            >
            <span v-else>{{ model.dutyMobile }}</span>
          </template>
        </van-cell>
        <van-cell title="HSE负责人">
          <template #default>
            <a
              v-if="model.hsdMobile"
              class="blue"
              :href="'tel:' + model.hsdMobile"
              >{{
                (model.hseHeader && model.hseHeader === "--"
                  ? ""
                  : model.hseHeader) +
                  " " +
                  model.hsdMobile
              }}</a
            >
            <span v-else>{{ model.hsdMobile }}</span>
          </template>
        </van-cell>
      </div>
    </van-pull-refresh>
    <IndicatorEchartsPopup v-model="echartsVisible" :model="model" />
  </div>
</template>

<script>
import { getIndicatorDetail } from "@/api/base";
import IndicatorEchartsPopup from "./components/indicatorEchartsPopup";
export default {
  name: "IndicatorDetail",
  components: {
    IndicatorEchartsPopup
  },
  data() {
    return {
      isLoading: false,
      defaultValue: "--",
      pageTitle: "详情",
      echartsVisible: false,
      echartsNoData: false,
      model: {
        id: "",
        name: "",
        num: "",
        value: "",
        collectTime: "",
        indicatorTypeName: "",
        threshold: "",
        alarmName: "",
        alarmTime: "",
        departmentName: "",
        poiName: "",
        dangerName: "",
        dutyMobile: "",
        hseHeader: "",
        hsdMobile: "",
        low: "",
        lowLow: "",
        high: "",
        highHigh: "",
        indicatorUnitName: "",
        handleStatus: ""
      }
    };
  },
  mounted() {
    this._setNeedRefreshList(true);
    this.getDetail();
  },
  methods: {
    popupOnOpened() {},
    onRefresh() {
      this.getDetail();
    },
    onClickRight() {
      const { id } = this.$route.params;
      this.$router.push({
        name: "Alarm",
        query: {
          id,
          from: "indicator-detail",
          name: this.model.name,
          num: this.model.num
        }
      });
    },
    onClickLeft() {
      history.go(-1);
    },
    showEcharts(v) {
      this.echartsVisible = v;
    },
    getDetail() {
      const { id } = this.$route.params;
      getIndicatorDetail(id)
        .then(res => {
          this.isLoading = false;
          this.pageTitle = res.name;
          for (let key in this.model) {
            const placeholder =
              key === "indicatorUnitName" ? "" : this.defaultValue;
            this.model[key] =
              res[key] !== 0 ? res[key] || placeholder : res[key];
            if (key === "alarmTime") {
              if (this.model.alarmTime) {
                this.model.alarmTime = Number(res.alarmTime) / 1000 + "s";
              } else {
                this.model.alarmTime = this.defaultValue;
              }
            }
          }
          this.model.value = isNaN(this.model.value)
            ? 0
            : parseFloat(this.model.value.toFixed(2));
        })
        .catch(e => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.indicator-detail {
  padding: 60px 0 20px 0;
  .history-list {
    font-size: 24px;
    color: #fff;
  }
}
.indicator-detail-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 8px 16px;
  align-items: center;
  .subtitle {
    color: #2e2e4d;
    font-size: 14px;
    font-weight: 400;
  }
  .iconfont {
    font-size: 24px;
    color: #1676ff;
  }
}
.indicator-detail-table {
  margin: 0 16px;
  table {
    width: 100%;
  }
  td {
    border: 1px solid #eceef2;
    font-size: 13px;
    text-align: center;
    width: 20%;
    word-break: break-all;
    padding: 13px 5px;
    vertical-align: middle;
    line-height: 1.5;
    &.blue {
      color: #1676ff;
    }
    &.red {
      color: #f13030;
    }
    &:nth-child(2n + 1) {
      color: #8c8f97;
    }
  }
}
.indicator-detail-list {
  margin-top: 10px;
  .blue {
    color: #1676ff;
  }
}
.popup-indicator-echarts {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding-top: 18px;
  .closeecharts {
    position: absolute;
    font-size: 18px;
    top: 8px;
    right: 8px;
    color: #9496a3;
  }
  .popup-indicator-warning {
    padding: 9px 16px;
    font-size: 12px;
    color: #3b4664;
    span {
      &:not(:last-child) {
        margin-right: 14px;
        position: relative;
        &::after {
          position: absolute;
          content: "/";
          right: -8px;
          top: 10px;
          transform: translateY(-50%);
        }
      }
    }
  }
  .popup-indicator-echarts-box {
    flex: 1;
    position: relative;
    &.nodata {
      &::after {
        position: absolute;
        content: "暂无数据";
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
